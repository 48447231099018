<template>
	<section class="gardenManagementPage">
		<g-breadcrumb bread="未审核订单管理" />
		<SearchForm>
			<el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px">
				<el-form-item prop="tradeNo">
					<el-input v-model.trim="searchForm.tradeNo" placeholder="请输入订单编号" />
				</el-form-item>
				<el-form-item prop="srcTids">
					<el-input v-model.trim="searchForm.srcTids" placeholder="请输入原始单号" />
				</el-form-item>
				<el-form-item prop="shopName">
					<el-select v-model="searchForm.shopName" placeholder="请选择店铺名称" @change="changeShop" filterable
						clearable>
						<el-option v-for="item in options" :key="item.dicCode" :label="item.dicName"
							:value="item.dicCode" />
					</el-select>
				</el-form-item>
				<!-- <el-form-item prop="shopName">
					<el-input v-model.trim="searchForm.shopName" placeholder="请输入店铺名称" />
				</el-form-item> -->
				<el-form-item prop="receiverMobile">
					<el-input v-model.trim="searchForm.receiverMobile" placeholder="请输入电话号码" />
				</el-form-item>
				<el-form-item prop="receiverMobile">
					<SearchForm>
						<div class="popperClass">
							<el-date-picker v-model.trim="searchForm.daterange" popper-class="popperClass"
								:picker-options="pickerOptions" type="daterange" range-separator="至"
								start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSearch" />
						</div>
					</SearchForm>
				</el-form-item>


				<el-form-item>
					<el-button type="primary" @click="fetchData">搜 索</el-button>
				</el-form-item>
			</el-form>
		</SearchForm>
		<TableContainer title="未审核订单列表">
			<template slot="button">
				<el-button type="success" @click="addTheme" class="turnBule">批量匹配</el-button>
			</template>
			<el-table :data="tableData" stripe style="width: 100%" border :header-cell-style="tabHeader"
				:cell-style="{ textAlign: 'center' }">

				<el-table-column prop="srcTids" label="原始单号" min-width="130" />
				<el-table-column prop="shopName" label="店铺名称" min-width="130" />
				<el-table-column prop="tradeNo" label="旺店通订单号" min-width="110" />
				<!-- <el-table-column prop="tradeNo" label="订单编号" min-width="100" /> -->
				<el-table-column prop="customerName" label="客户昵称" min-width="100" />
				<el-table-column prop="customerId" label="买家ID" min-width="100" />
				<!-- <el-table-column prop="customerNo" label="客户编码" min-width="100" /> -->
				<el-table-column prop="receiverName" label="收件人" min-width="60" />
				<el-table-column prop="receiverArea" label="区域" min-width="150" />
				<el-table-column prop="receiverAddress" label="地址" min-width="150" />
				<el-table-column prop="receiverMobile" label="手机" min-width="100" />
				<el-table-column prop="paid" label="已付金额" min-width="60" />
				<el-table-column prop="created" label="订单日期" min-width="130" />
				<!-- <el-table-column prop="id" label="id" min-width="160" /> -->
				<!-- <el-table-column prop="receiverName" label="收件人" min-width="60" /> -->
				<!-- <el-table-column prop="srcTids" label="原始单号" min-width="130" />
				<el-table-column prop="shopName" label="店铺名称" min-width="100" />
				<el-table-column prop="customerNo" label="客户编码" min-width="100" />
				<el-table-column prop="customerName" label="客户昵称" min-width="100" />
				<el-table-column prop="customerId" label="买家ID" min-width="100" />
				<el-table-column prop="customerNo" label="客户编码" min-width="100" />
				<el-table-column prop="receiverName" label="收件人" min-width="60" />
				<el-table-column prop="receiverAddress" label="地址" min-width="150" />
				<el-table-column prop="receiverMobile" label="手机" min-width="100" />
				<el-table-column prop="paid" label="已付金额" min-width="100" />
				<el-table-column prop="created" label="订单日期" min-width="130" /> -->
				<el-table-column width="100" label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden(scope.row)">待审核</el-button>
						<!-- <el-button type="text" class="textOrange" v-else>匹配成功</el-button> -->
					</template>
				</el-table-column>
			</el-table>
			<GPagination v-show="total > 0" ref="ref_GPagination" :total="total" :page.sync="searchForm.page"
				:size.sync="searchForm.size" @pagination="fetchData" />
		</TableContainer>

		<GDialog :dialog.sync="gardenDialog" @btnSure="submitThemeForm()" @btnClose="resetGardenForm()">
			<SearchForm>
				<el-form ref="ref_searchForm" :inline="true" :model="searchForm" label-width="80px"
					@submit.native.prevent>
					<el-form-item label="" prop="realName">
						<el-input v-model="searchForm1.realName" placeholder="请输入用户名称" />
					</el-form-item>
					<el-form-item label="" prop="mobilePhone">
						<el-input v-model="searchForm1.mobilePhone" placeholder="请输入用户手机号码" />
					</el-form-item>
					<el-button type="primary" @click="fetchData1">搜 索</el-button>
					</el-form-item>
				</el-form>
			</SearchForm>

			<el-table :data="tabList" stripe style="width: 100%" border :header-cell-style="tabHeader"
				:cell-style="{ textAlign: 'center' }">
				<el-table-column prop="realName" label="用户昵称" min-width="160" />
				<el-table-column prop="userName" label="用户姓名" min-width="160" />
				<el-table-column prop="sexName" label="用户性别" min-width="100" />
				<el-table-column prop="buyStatus" label="购买状态" min-width="100" />
				<el-table-column prop="address" label="地址" min-width="100" />
				<el-table-column prop="mobilePhone" label="手机号码" min-width="100" />
				<el-table-column prop="regName" label="注册时间" min-width="100" />
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" class="reviewed" @click="editGarden2(scope.row)">匹配</el-button>
					</template>
				</el-table-column>
			</el-table>
			<GPagination v-show="total1 > 0" ref="ref_GPagination" :total="total1" :page.sync="searchForm1.page"
				:size.sync="searchForm1.size" @pagination="fetchData1" />
		</GDialog>

	</section>

</template>

<script>
	import {
		mapState
	} from 'vuex'
	import {
		noBeReviewedTrade,
		findAllUser,
		updateUserStatus,
		getDictoryInfoByCode,
		batchUpdateUserStatus
	} from '@api/gardenManagement/api_gardenManagement'
	export default {
		data() {
			return {
				currentRow: {},
				tableData: [],
				tabList: [],
				options: [],
				total: 0,
				total1: 0,
				searchForm1: {
					realName: '',
					mobilePhone: '',
					page: 1,
					size: 20,
				},
				beginTime: '',
				endTime: '',
				gardenDialog: {
					title: '标题哦',
					visible: false
				},
				gardenDialog1: {
					title: '匹配信息',
					visible: false
				},
				pickerOptions: {
					onPick({
						maxDate,
						minDate
					}) {
						// 当开始时间结束时间都选择了之后才执行搜索
						if (maxDate && minDate) {
							this.$nextTick(() => {
								that.handleSearch()
							})
						}
					}
				},

				searchForm: {
					// merchantId: 0,
					srcTids: "",
					shopName: "",
					receiverMobile: "",
					realName: "",
					mobilePhone: "",
					daterange: "",
					page: 1,
					size: 20,
					startNum: 1,
					beginTime: '',
					endTime: '',
				},
				gardenForm: {
					areaId: '',
					orgName: '',
					principalName: '',
					phone: '',
					remark: ''
				},
				tradeId: 0,
				tradeNo: "",
			}
		},
		// computed: {
		// 	...mapState('commonModule', ['orgAreaTree'])
		// },
		created() {
			// this.getOptions()
			this.fetchData()
			this.getDictoryInfoByCode()
		},

		methods: {

			tabHeader() {
				return 'backgroundColor:rgba(88, 227, 232, 0.12);color:#000;font-weight:bold;font-size:15px;text-align: center;'
			},
			getDictoryInfoByCode() {
				getDictoryInfoByCode().then(res => {
					this.options = res
				})
			},
			changeShop() {
				this.searchForm.dicCode = val
			},
			fetchData() {
				this.searchForm.type = 3
				noBeReviewedTrade(this.searchForm).then(res => {
					// console.log(res)
					this.tableData = res.records
					this.total = res.total
				})
			},
			addTheme() {
				batchUpdateUserStatus().then(res => {
					console.log(res)
					 this.$alert(res, '匹配信息', {
					          dangerouslyUseHTMLString: true
					        });
				})
			},
			fetchData1() {
				findAllUser(this.searchForm1).then(res => {
					this.tabList = res.records
					this.total1 = res.total
				})
			},
			formatDate(date, fmt) {
				if (/(y+)/.test(fmt)) {
					fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
				}
				let o = {
					'M+': date.getMonth() + 1,
					'd+': date.getDate(),
					'h+': date.getHours(),
					'm+': date.getMinutes(),
					's+': date.getSeconds()
				}
				for (let k in o) {
					if (new RegExp(`(${k})`).test(fmt)) {
						let str = o[k] + ''
						fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : this.padLeftZero(str))
					}
				}
				return fmt
			},
			padLeftZero(str) {
				return ('00' + str).substr(str.length)
			},
			handleSearch(val) {
				// console.log(val,1111)
				// console.log(val[0],1111)
				let date = val[0];
				let date1 = val[1];
				this.searchForm.beginTime = this.formatDate(date, 'yyyy-MM-dd hh:mm:ss');
				console.log(this.searchForm.beginTime, 1111)
				this.searchForm.endTime = this.formatDate(date1, 'yyyy-MM-dd hh:mm:ss');
				// console.log(this.beginTime,1111)

			},
			editGarden(row) {
				this.gardenDialog.visible = true
				this.gardenDialog.title = '用户列表'
				this.tradeId = row.tradeId;
				this.tradeNo = row.tradeNo;
				// this.themeForm = row
				let params = {
					page: 1,
					size: 20,
				}
				findAllUser(params).then((res) => {
					console.log(res, 1111)
					this.tabList = res.records
					console.log(this.tabList)
					this.total1 = res.total
				})
			},
			editGarden2(row) {
				console.log(row, 5555)
				let params = {
					tradeId: this.tradeId,
					tradeNo: this.tradeNo,
					userId: row.userId,
					buyStatus: row.buyStatus,
					type: row.flag
				}
				updateUserStatus(params).then((res) => {
					this.$confirm('是否确认匹配?', '提示', {
						type: 'warning',
						closeOnClickModal: false
					}).then(() => {
						this.$message.success('匹配成功')
						this.gardenDialog.visible = false;
						this.fetchData()
					})
				})


			},
			submitThemeForm() {
				this.gardenDialog.visible = false
			},
			resetGardenForm(formName) {
				// this.$refs[formName].resetFields()
				this.gardenDialog.visible = false
			}

		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.customWidth {
		width: 50%;
		// text-align: center;
	}

	.popperClass {
		.el-date-editor .el-range-separator {
			width: 20%;
		}
	}

	.turnBule {
		color: #fff;
		background-color: #58e3e8;
		border-color: rgba(56, 185, 190, 0.12);
	}

	.turnBule:hover,
	.turnBule:focus {
		background: rgba(56, 185, 190, 0.5);
		border-color: rgba(56, 185, 190, 0.5);
		color: #fff;
	}

	.reviewed {
		color: #38b9be;
	}
</style>
